import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Global, css } from '@emotion/core'
import { useForm } from 'react-form'
import { Row, Col } from 'react-grid-system'

import Layout from '../components/layout'
import CourseSelector from '../components/forms/course-selector'
import Button from '../components/button'
import { CenterTextDiv, VerticalSpace } from '../components/common'
import { TextInput } from '../components/forms/inputs'
import SEO from '../components/seo'
import { courses, courseTimes } from '../components/forms/data'

const SubmitButton = Button.withComponent('button')

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const requiredFn = (value) => (!value ? 'Pflichtangabe' : false)

const Booking = () => {
  const {
    Form,
    values,
    meta: { isSubmitting },
  } = useForm({
    onSubmit: async (values) => {
      try {
        await fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'booking',
            ...values,
            course_day: values.course_day.join(' ;; '),
            course_times: values.course_times.join(' ;; '),
          }),
        })
      } catch (error) {
        // TODO
      } finally {
        navigate('/buchung-abgeschlossen', { replace: true })
      }
    },
    debugForm: false,
  })

  return (
    <Layout>
      <SEO title="Kurse Buchen" />
      <Global
        styles={css`
          h4 {
            font-size: 1.1rem;
            margin-bottom: 1rem;
            line-height: 1;
          }

          p {
            font-size: 0.9rem;
          }
        `}
      />
      <Form
        name="booking"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <CenterTextDiv>
          <h4>1. Kontaktdaten ausfüllen</h4>

          <p>
            Alle auf diesem Formular erfassten Teilnehmerdaten werden streng
            vertraulich behandelt und nicht an Dritte weitergegeben.
          </p>
        </CenterTextDiv>
        <CenterTextDiv>
          <h5>Name und Anschrift</h5>
        </CenterTextDiv>
        <Row>
          <Col sm={6}>
            <TextInput
              name="first_name"
              placeholder="Vorname"
              required
              autoComplete="booking given-name"
              validate={requiredFn}
            />
          </Col>
          <Col sm={6}>
            <TextInput
              name="last_name"
              placeholder="Nachname"
              required
              autoComplete="booking family-name"
              validate={requiredFn}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <TextInput
              name="street_housenumber"
              placeholder="Straße und Hausnummer"
              required
              autoComplete="booking street-address"
              validate={requiredFn}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <TextInput
              name="zip"
              placeholder="PLZ"
              required
              autoComplete="booking postal-code"
              validate={requiredFn}
            />
          </Col>
          <Col sm={9}>
            <TextInput
              name="city"
              placeholder="Ort"
              required
              autoComplete="booking address-level2"
              validate={requiredFn}
            />
          </Col>
        </Row>
        <CenterTextDiv style={{ marginTop: '1rem' }}>
          <h5>Kontakt</h5>
        </CenterTextDiv>
        <Row>
          <Col sm={6}>
            <TextInput
              name="phone"
              placeholder="Telefon (Mobil)"
              type="phone"
              autoComplete="booking tel-country-code"
            />
          </Col>
          <Col sm={6}>
            <TextInput
              name="landline"
              placeholder="Telefon (Festnetz)"
              type="phone"
              autoComplete="booking tel-country-code"
            />
          </Col>
          <Col sm={6}>
            <TextInput
              name="email"
              placeholder="E-Mail Adresse"
              required
              type="booking email"
              validate={requiredFn}
            />
          </Col>
        </Row>

        <VerticalSpace />

        <CenterTextDiv>
          <h4>
            2. Wähle einen oder mehrere Kurse, an denen Du teilnehmen möchtest
          </h4>
          <p>
            Bei Teilnahme an mehreren Kursen pro Woche erhälst du{' '}
            <strong>10% Ermäßigung</strong>
          </p>
        </CenterTextDiv>

        <CourseSelector formId="course_day" data={courses} selectable />
        {/* For netlify */}
        <input type="hidden" name="course_day" />

        <VerticalSpace />

        <CenterTextDiv>
          <h4>3. Ab wann möchtest du den Kurs starten? (Daten für 2024)</h4>
          <p>
            Die Teilnehmerzahlen pro Kurs sind begrenzt. Sichere Dir direkt
            einen Platz für das ganze Jahr!
          </p>
        </CenterTextDiv>

        <CourseSelector
          columnWidth={320}
          formId="course_times"
          data={courseTimes}
          selectable
        />
        {/* For netlify */}
        <input type="hidden" name="course_times" />

        <SubmitButton type="submit" disabled={isSubmitting}>
          Buchungsanfrage abschicken
        </SubmitButton>
        <br />
        <CenterTextDiv>
          Wir prüfen deine Anmeldung und melden uns innerhalb von 24 Stunden.
        </CenterTextDiv>
      </Form>
    </Layout>
  )
}

export default Booking
