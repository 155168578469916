import React from 'react'
import { useField } from 'react-form'
import styled from '@emotion/styled'

const InputContainer = styled.div`
  position: relative;
  margin: 1.2rem 0;
`

const Input = styled.input`
  width: 100%;
  background-color: #f8f8f8;
  text-shadow: none;
  box-shadow: none;
  padding: 1rem;
  border: 1px solid #696969;

  &::placeholder {
    color: #696969;
    opacity: 1;
  }

  &.errored {
    background: #f7cfd4 !important;
  }
`

const Label = styled.label`
  font-size: 0.8rem;
  position: absolute;
  top: -20px;
  left: 15px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  color: #696969;

  &.visible {
    opacity: 1;
  }
`

const RequiredEM = styled.em`
  font-size: 0.8rem;
  font-style: normal;
  color: #c15561;
  position: absolute;
  bottom: -20px;
  left: 0;
  font-weight: 700;
`

export function TextInput({ name, validate, ...rest }) {
  const {
    meta: { error, isTouched },
    getInputProps,
  } = useField(name, {
    validate,
  })

  const inputProps = getInputProps()
  const placeholder = rest.placeholder + (rest.required ? '*' : '')

  return (
    <InputContainer>
      <Label className={Boolean(inputProps.value) ? 'visible' : ''}>
        {rest.placeholder}
      </Label>
      <Input
        {...inputProps}
        {...rest}
        name={name}
        placeholder={placeholder}
        className={isTouched && error && 'errored'}
      />{' '}
      {isTouched && error ? <RequiredEM>{error}</RequiredEM> : null}
    </InputContainer>
  )
}
